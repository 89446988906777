
import { Vue, Component, Watch } from 'vue-property-decorator';
import { PlanoPagamentoService } from '@/core/services/contratos';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { PlanoPagamentoModelo } from '@/core/models/contratos';
 
@Component
export default class ListaTipoReceita extends mixins(Vue,ListPage) {
  item = new PlanoPagamentoModelo();
  service = new PlanoPagamentoService();

  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;

  titulo: string = 'Plano de Pagamento';
  subTitulo: string = 'Lista dos planos de pagamentos para o uso no Sistema';
 
  options: any = {
    itemsPerPage: 15
  };

  headers: any[] = [ 
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
  ];

 @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true; 

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }

  Novo(){
    this.item = new PlanoPagamentoModelo();
    this.dialogCadastro = true;
  }

  Editar(item){
    this.service.ObterPorId(item.id).then(
      (res) =>{
        this.item = new PlanoPagamentoModelo(res.data);
        this.dialogCadastro = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
  }
  mounted(){

  }
}

